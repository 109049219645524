import styled from 'styled-components';

export const DashboardStyle = styled.form`
.dashboard-buttons{
  width: 40%;
}
.videoProjector{
  margin: auto;
  max-width: 500px;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider {
  flex-grow: 1;
  height: 1px;
  background-color: #9f9f9f;
  margin-left: 20px;
}

  .dashboard {
    font-family: ${(props) => props?.theme.fonts.secondary};
    background: ${(props) => props?.theme.colors['original-light']};
  

    header{
      background: ${(props) => props?.theme.colors.original};
    }

    &__close{
      width: 50%;
      margin: auto;
      padding-bottom: 30px;
    }
    
   
    section {
      padding: 12px;
      display: flex;
      flex-direction: column;
    }

    &__programs {
      display: flex;
      margin: auto;

      &--line {
        display: flex;
        gap: 30px;
        justify-content:space-around;
      }

      &--notification{
        font-size: .8rem;
background-color: ${(props) => props?.theme.colors['green-100']};
border: 1px solid ${(props) => props?.theme.colors['green-200']};;
width: 100%;
border-radius: 4px;
display: flex;
padding: 5px 10px;
margin-bottom: 30px;
margin-top: 30px;
animation: fadeOut 1s forwards 10s;


@keyframes fadeOut {
  0%,70%{
    opacity: 0;
  }
  100%{
    opacity: 0;
    display: none;
  }
}
}
    }

    &__id {
      font-size: 1rem;
      color: ${(props) => props?.theme.colors.black};
      padding: 3px;
      margin-bottom: 30px;
      margin-top: 0;
    }
  }
  
  @media screen and (min-width: 400px) {
    
    .dashboard {
      &__Allprograms{
        margin: auto;
        padding: 20px;
      }
    
      &__Cards{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
      }

      section {
        display: flex;
        flex-direction: row;
        align-items: center; 
      }
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .dashboard-buttons{
      width: 45%;
    }

     .dashboard {
    font-family: ${(props) => props?.theme.fonts.secondary};
    

    &__Allprograms{
      margin: auto;
      padding: 20px;
      width: 1000px;
    }
    
    &__sectionbg {
      width: 100%;
      padding-top:30px;
      padding-bottom:30px;

      &--payPrograms{
        width:1000px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
      }
    }

    &__close{
      display: none;
    }

    section {
      display: flex;
      flex-direction: row;
    }

    &__programs {
      width: 980px;
      display: flex;
      margin: auto;

      &--line {
        display: flex;
        gap: 30px;
        margin-left: 5px;      
      }
      
    }

    &__AllPrograms  {
      display: flex;
      margin: auto;
    }

    &__Cards{
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 1000px;
      margin: auto;
   
    }

    &__id {
      font-size: 1.5rem;
      color: ${(props) => props?.theme.colors.black};
      background:none;
      padding: 3px;
    }
  }
`;
