import React, { useState, useEffect } from 'react';
import { DashboardStyle } from './dashboardStyle';
import HeaderPrivate from '../../components/headerPrivate/HeaderPrivate';
import { useAppSelector, useAppDispatch } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import usePrograms from '../../hooks/dataPrograms';
import Cards from '../../components/Cards/Cards';
import SingOutButton from '../../components/forms/SingOut';
import DinamicVlog from '../../components/DinamicVlog/DinamicVlog';
import Programs from '../../components/Programs/Programs';
import Footer from '../../components/footer/Footer';
import DrawerUser from '../../components/DrawerUser/DrawerUser';
import DrawerMessages from '../../components/DrawerMessages/DrawerMessage';
import useUser from '../../hooks/use-user';

import { idx } from '../../redux/reducers';
import { createComponent } from '@lit/react';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';

type MyElementComponentProps = any;

const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

const Dashboard = () => {
  const user = useAppSelector((state) => state.logUser.value);
  const userCode = useAppSelector((state) => state.logUser);

  const { api } = usePrograms();
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isOpenUser, setIsOpenUser] = useState<boolean>(false);
  const { userdb: info, loading: userLoading } = useUser();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get('status');


  useEffect(() => {
    if (!userLoading && info?.docId !== undefined && userCode.idx === 'empty') {
      const addIdx = () => {
        dispatch(idx(info.docId));
      };

      addIdx();
    }
  }, [info, userLoading]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawerUser = () => {
    if (isDrawerOpen) {
      setDrawerOpen(false);
    }
    setIsOpenUser(!isOpenUser);
  };

  const closeDrawerUser = () => {
    setIsOpenUser(false);
  };

  const name =
    typeof user !== 'undefined' || null
      ? `${user?.user?.displayName}`
      : 'dashboardError';

  return (
    <DashboardStyle>
      <DrawerMessages isDrawerOpen={isDrawerOpen} onClose={closeDrawer} />
      <DrawerUser isOpenUser={isOpenUser} onCloseUser={closeDrawerUser} />

      <main className="dashboard">
        <header>
          <HeaderPrivate
            toggleDrawer={toggleDrawer}
            toggleDrawerUser={toggleDrawerUser}
          />
        </header>

        <section className="dashboard__programs">
          <div>
            {status !== null && (
              <div className="dashboard__programs--notification">
                Notification: {status}
              </div>
            )}

            <p className="dashboard__id">@{name.substring(0, 12)}</p>
            <div>
              {userCode.idx !== undefined &&
              userCode.idx === process.env.REACT_APP_KEY_APP ? (
                <div
                  style={{
                    marginBottom: '50px',
                    display: 'flex',
                    gap: '15px',
                  }}>
                  <a href="/listusers">
                    <MyElementComponent
                      labelTemplate="Go to Users"
                      colors="wh4u"
                      type="button"></MyElementComponent>
                  </a>
                  <MyElementComponent
                    labelTemplate="Payments"
                    colors="wh4u"
                    type="button"></MyElementComponent>
                </div>
              ) : null}
            </div>

            <div className="container">
              <h2>Your Programs </h2>
              <div className="divider"></div>
            </div>

            <p
              style={{ fontFamily: "'Abel', sans-serif", fontSize: '16px' }}
              className="animation">
              It's great to have you here! Take a moment to explore the
              available free and paid programs, enhance your expertise, and stay
              updated with our latest news.
            </p>
          </div>
        </section>

        <div className="dashboard__sectionbg">
          <section className="dashboard__sectionbg--payPrograms">
            <Programs />
          </section>
        </div>

        <div className="dashboard__Allprograms">
          <div className="container">
            <h2>All Programs</h2>
            <div className="divider"></div>
          </div>
        </div>

        <section className="dashboard__Cards">
          {api?.programs?.length !== undefined ? (
            <>
              {api?.programs.map((item) => {
                return (
                  <Cards
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    content={item.content}
                    price={item.price}
                    image={item.image}
                    language={item.language}
                    link={item.link}
                    external={item.external}
                  />
                );
              })}
            </>
          ) : null}
        </section>


    
        <div className="dashboard__Allprograms">
          <div className="container">
            <h2>Recent News</h2>
            <div className="divider"></div>
          </div>
        </div>
        <DinamicVlog />
        <div className="dashboard__close">
          <SingOutButton />
        </div>
      </main>
      <Footer />
    </DashboardStyle>
  );
};

export default Dashboard;
