export const ProgramList = [
  {
    id: 1,
    title: 'CSS Animations',
    content:
      'Master CSS Animations by learning about transitions and keyframes animations. Discover how to create smooth and visually engaging transitions, and use keyframes to define complex animation sequences. CSS allows animation of HTML elements without using JavaScript. *Requires knoledge of HTML and CSS',
    episodes: [
      { id: 1, title: 'CSS Transitions animations 23:18 min' },
      { id: 2, title: 'Keyframes animations 26:27 min' },
      { id: 3, title: 'Animation compositions 08:11 min' },
      { id: 4, title: 'CSS Animations Scroll - September 14th 2024' },
    ],
    image: '/webComponents',
    language: 'EN',
    price: '65 PLN',
  },
  {
    id: 2,
    title: 'Javascript PRO',
    content:
      'Desbloquea todo el potencial de JavaScript con la guía más completa y exhaustiva disponible. Ya seas un principiante o un desarrollador experimentado, este programa está diseñado para llevar tus habilidades en JavaScript al siguiente nivel.',
    episodes: [
      {
        id: 1,
        title:
          'Una Introducción: Introducción a JavaScript, Manuales y especificaciones, Editores de código, Consola del desarrollador',
      },
      {
        id: 2,
        title:
          'Fundamentos de JavaScript: Hola, mundo!, Estructura del código, El modo moderno, "use strict", Variables, Tipos de datos, Interacción: alert, prompt, confirm, Conversiones de tipo, Operadores básicos, matemáticas, Comparaciones, Ramificación condicional: if, "?", Operadores lógicos, Operador de coalescencia nula "??", Bucles: while y for, La declaración "switch", Funciones, Expresiones de función, Funciones flecha, lo básico, Particularidades de JavaScript',
      },
      {
        id: 3,
        title:
          'Calidad del código: Depuración en el navegador, Estilo de codificación, Comentarios, Código ninja, Pruebas automatizadas con Mocha, Polyfills y transpiladores',
      },
      {
        id: 4,
        title:
          'Objetos: lo básico: Objetos, Referencias y copia de objetos, Recolección de basura, Métodos de objetos, "this", Constructor, operador "new", Encadenamiento opcional "?.", Tipo Symbol, Conversión de objeto a primitivo',
      },
      {
        id: 5,
        title:
          'Tipos de datos: Métodos de primitivos, Números, Cadenas, Arreglos, Métodos de arreglos, Iterables, Map y Set, WeakMap y WeakSet, Object.keys, values, entries, Asignación de desestructuración, Fecha y hora, Métodos JSON, toJSON',
      },
      {
        id: 6,
        title:
          'Trabajo avanzado con funciones: Recursión y pila, Parámetros rest y sintaxis de propagación, Ámbito de variables, clausura, El viejo "var", Objeto global, Objeto función, NFE, La sintaxis "new Function", Programación: setTimeout y setInterval, Decoradores y reenvío, call/apply, Enlace de funciones, Funciones flecha revisitadas',
      },
      {
        id: 7,
        title:
          'Configuración de propiedades de objetos: Banderas y descriptores de propiedades, Getters y setters de propiedades',
      },
      {
        id: 8,
        title:
          'Prototipos, herencia: Herencia prototípica, F.prototype, Prototipos nativos, Métodos de prototipos, objetos sin __proto__',
      },
      {
        id: 9,
        title:
          'Clases: Sintaxis básica de clases, Herencia de clases, Propiedades y métodos estáticos, Propiedades y métodos privados y protegidos, Extensión de clases incorporadas, Verificación de clase: "instanceof", Mixins',
      },
      {
        id: 10,
        title:
          'Manejo de errores: Manejo de errores, "try...catch", Errores personalizados, extender Error',
      },
      {
        id: 11,
        title:
          'Promesas, async/await: Introducción: callbacks, Promesa, Encadenamiento de promesas, Manejo de errores con promesas, API de promesas, Promisificación, Microtareas, Async/await',
      },
      {
        id: 12,
        title:
          'Generadores, iteración avanzada: Generadores, Iteración y generadores asíncronos',
      },
      {
        id: 13,
        title:
          'Módulos: Módulos, introducción, Exportar e importar, Importaciones dinámicas',
      },
      {
        id: 14,
        title:
          'Misceláneos: Proxy y Reflect, Eval: ejecutar una cadena de código, Currificación, Tipo de referencia, BigInt, Unicode, Internos de cadenas, WeakRef y FinalizationRegistry',
      },
    ],
    image: '/javascript',
    language: 'EN',
    price: '155 PL, ó, 155.000 COP',
  },
];
