import React from 'react';
import { StyleCard } from './StyleCard';

interface CardInformation {
  title: string | undefined;
  content: string | undefined;
  image: string | undefined;
  language: string;
  price: string;
  external?: string[];
}

const CardsPublic: React.FC<CardInformation> = ({
  title,
  content,
  image,
  language,
  price,
  external,
}) => {
  return (
    <StyleCard>
      <>
        <div className="Card">
          <div className="Card__header">
            <h2>
              <b>{`${language} `}</b>
              <span>
                {language === 'ES' ? 'Aprende' : ''} {title}
              </span>
            </h2>
          </div>

          <a
            href={
              external && external[0] === 'yes'
                ? `${external[1]}`
                : `/descriptions/${title}`
            }
            rel="noreferrer"
            target="_blank">
            <picture>
              <source
                srcSet={`${process.env.PUBLIC_URL}${image}-small.png`}
                media="(min-width: 700px)"
              />
              <source
                srcSet={`${process.env.PUBLIC_URL}${image}-medium.png`}
                media="(min-width: 1024px)"
              />
              <img
                src={`${process.env.PUBLIC_URL}${image}-small.png`}
                alt="Javascript"
                width="100%"
                height="100%"
                className="Image-Cards"
              />
            </picture>
            <p className="Card__content">{content}</p>
            {price !== '00' ? (
              <div className="Card__price">{Number(price) / 100} PLN</div>
            ) : (
              <div className="Card__price">Free</div>
            )}
          </a>
        </div>
      </>
    </StyleCard>
  );
};

export default React.memo(CardsPublic);
