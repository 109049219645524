import AWS from 'aws-sdk';

interface DigitalOcean {
  videoKey: string;
  dir: string; // Añade el directorio como parte de la interfaz
}

const generatePresignedUrl = async (item: DigitalOcean) => {
  // Configure the S3 client
  const spacesEndpoint = new AWS.Endpoint('fra1.digitaloceanspaces.com'); // Replace with your region
  const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: process.env.REACT_APP_DO_KEY, 
    secretAccessKey: process.env.REACT_APP_DO_PASS,
  });

  // Parameters for the pre-signed URL
  const params = {
    Bucket: 'wh4u2025', // Replace with your bucket name
    Key: `${item.dir}/${item.videoKey}`, // Incluye el directorio en la ruta del archivo
    Expires: 3600, // URL expires in 1 hour (adjust as needed)
  };

  try {
    // Generate the pre-signed URL
    const url = await s3.getSignedUrlPromise('getObject', params);
    return url;
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    return null;
  }
};

export default generatePresignedUrl;